import styled from 'styled-components';

const BankIcons = ({ hideIcon }) => (
    <BankIconsWrapper hideIcon={hideIcon}>
        <BankIconMainContainer>
            <BankIconContainer width="250px">
                <BankIcon
                    src="https://cloud.keikei.com/cdn/banners/logo-paypal.png"
                    alt="mastercard"
                    width="auto"
                    height="20px"
                    loading="lazy"
                />
                <BankIcon
                    src="https://cloud.keikei.com/cdn/fervente/icons/visa.jpg"
                    alt="visa"
                    width="auto"
                    height="16px"
                    loading="lazy"
                />
                <BankIcon
                    src="https://cloud.keikei.com/cdn/fervente/icons/mastercard.jpg"
                    alt="mastercard"
                    width="auto"
                    height="20px"
                    loading="lazy"
                />
                <BankIcon
                    src="https://cloud.keikei.com/cdn/icons/icon-payment-amex_2.svg"
                    alt="amex"
                    width="auto"
                    height="24px"
                    loading="lazy"
                />
            </BankIconContainer>
        </BankIconMainContainer>
    </BankIconsWrapper>
);

const BankIconsWrapper = styled.div`
    width: 100%;
    padding: 20px 0;
    @media (max-width: 1024px) {
        margin-bottom: 50px;
        display: ${({ hideIcon }) => (hideIcon ? 'none' : 'block')};
        border-bottom: ${({ theme }) => `1px solid ${theme.colors.bgGray10}`};
    }
    @media (max-width: 768px) {
        display: none;
    }
`;

const BankIconMainContainer = styled.div`
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
`;

const BankIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${({ width }) => width || '700px'};
    margin: 0 auto;
`;
const BankIcon = styled.img``;
export default BankIcons;
