import { NextSeo } from 'next-seo';
import { useTranslation } from '@hooks';
import { useEffectOnce } from 'react-use';
import { viewPromotion } from '@lib/gtm';

import { ParallaxSlider } from '@components/homepage';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useAccount } from '@context/AccountContext';

const Home = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const { userData } = useAccount();

    useEffectOnce(() => {
        viewPromotion({
            id: 'fallwinter-24/24',
            name: 'City Rendez-vous',
            creative: 'City Rendez-vous',
            slot: 'main_large_banner',
            email: userData?.data?.data.email || '',
            phone:
                userData?.data?.data?.phone?.length > 5
                    ? userData?.data.data.phone
                    : '',
        });
    });
    const getCanonicalURL = () =>
        router?.locale === 'uk'
            ? `https://www.keikei.com${router.asPath}`
            : `https://www.keikei.com/${router.locale}${router.asPath}`;
    return (
        <>
            <Head>
                <script
                    type="application/ld+json"
                    async
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{
                        __html: JSON.stringify({
                            '@context': 'http://schema.org',
                            '@type': 'Organization',
                            name: 'KeiKei',
                            url: 'https://www.keikei.com/',
                            logo: 'https://cloud.keikei.com/cdn/banners/LOGO3.png',
                            contactPoint: [
                                {
                                    '@type': 'ContactPoint',
                                    telephone: '+90-850-259-14-41',
                                    contactType: 'customer service',
                                },
                            ],
                            sameAs: [
                                'https://www.facebook.com/KeiKei.co/',
                                'https://www.instagram.com/keikei.co/',
                            ],
                        }),
                    }}
                />
            </Head>
            <NextSeo
                title={`${t('keikeiTitle')} ${t('home')}`}
                description="Keikei Homepage"
                canonical={getCanonicalURL()}
            />
            <ParallaxSlider />
        </>
    );
};

export default Home;
